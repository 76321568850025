/* eslint-disable max-len */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIsDHCatalogSourceInUrl } from 'domains/catalog/Catalog.utils';
import { Product } from 'pages/CatalogPage/DH/Product/Product';
import { SeePlateLink } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/SeePlateLink';
import { useResetScroll } from 'utils/hooks/useResetScroll';

export const ProductSection = () => {
  const { referenceNumber } = useParams<{ referenceNumber: string }>();
  const isDHCatalogSourceInUrl = useIsDHCatalogSourceInUrl();
  const seePlateState = useState<string | undefined>(undefined);

  useResetScroll([referenceNumber]);

  const seePlateComp = (
    <>
      {isDHCatalogSourceInUrl && seePlateState && (
        <SeePlateLink referenceNumber={referenceNumber} seePlateState={seePlateState} fromDetail={true} />
      )}
    </>
  );

  return <Product key={referenceNumber} referenceNumber={referenceNumber} seePlate={seePlateComp} />;
};
