import React, { useState } from 'react';
import styled from 'styled-components';
import { useIsDHCatalogSourceInUrl } from 'domains/catalog/Catalog.utils';
import { Product } from 'pages/CatalogPage/DH/Product';
import { SeePlateLink } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/SeePlateLink';
import { Modal } from 'UI';

interface ProductModalProps {
  triggerComponent: React.ReactNode;
  referenceNumber: string;
  seePlate?: React.ReactNode;
  isVehicleCatalog?: boolean;
  scrollToSubstitutes?: boolean;
  id?: string;
  isUniversalProduct?: boolean;
}

const SModal = styled(Modal)`
  .ant-modal-body {
    padding: 0 0 30px 0;
  }
`;

const ProductModal = ({
  triggerComponent,
  referenceNumber,
  scrollToSubstitutes,
  isVehicleCatalog,
  id,
  isUniversalProduct,
}: ProductModalProps) => {
  const [showModal, setShowModal] = useState(false);
  const isDHCatalogSourceInUrl = useIsDHCatalogSourceInUrl();
  const seePlateState = useState<string | undefined>(undefined);

  const handleChangeShowModal = () => {
    setShowModal((prev) => !prev);
  };

  const seePlateComp = (
    <>
      {isDHCatalogSourceInUrl && seePlateState && (
        <SeePlateLink
          referenceNumber={referenceNumber}
          seePlateState={seePlateState}
          fromDetail={true}
          onClick={() => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );

  return (
    <>
      <div onClick={handleChangeShowModal} key={id}>
        {triggerComponent}
      </div>
      <SModal
        open={showModal}
        footer={''}
        onCancel={() => setShowModal(false)}
        width={'80%'}
        zIndex={1000}
        key={`modal-${id}`}
      >
        <Product
          referenceNumber={referenceNumber}
          isPopin
          seePlate={seePlateComp}
          scrollToSubstitutes={scrollToSubstitutes}
          isVehicleCatalog={isVehicleCatalog}
          isUniversalProduct={isUniversalProduct}
        />
      </SModal>
    </>
  );
};

export default ProductModal;
